import { useCallback } from 'react';

import { withRouter } from 'react-router';
import type { RouteComponentProps } from 'react-router';

import type { Account } from 'mastodon/models/account';
import type { Status } from 'mastodon/models/status';

type PropsBase = RouteComponentProps &
  Pick<React.HTMLProps<HTMLAnchorElement>, 'className' | 'title' | 'dangerouslySetInnerHTML' | 'children'>;

type AccountLinkProps = PropsBase & { account: Account; /* or Status::Mention */ };
const AccountLinkWithRouter: React.FC<AccountLinkProps> = ({
  account,
  history,
  children,
  ...rest
}) => {
  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 0 && !(e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      history.push(`/@${account.get('acct')}`);
    }
  }, [account, history]);

  return (
    <a
      href={account.get('url')}
      onClick={onClick}
      {...rest}
    >{children}</a>
  );
};
export const AccountLink = withRouter(AccountLinkWithRouter);

type StatusLinkProps = PropsBase & { status: Status; account?: Account; };
const StatusLinkWithRouter: React.FC<StatusLinkProps> = ({
  status,
  account,
  history,
  children,
  ...rest
}) => {
  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 0 && !(e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      const acct = account ? account.get('acct') : status.getIn(['account', 'acct']) as string;
      const statusId = status.get('id') as string;
      history.push(`/@${acct}/${statusId}`);
    }
  }, [account, history, status]);

  return (
    <a
      href={status.get('url') as string}
      onClick={onClick}
      {...rest}
    >{children}</a>
  );
};
export const StatusLink = withRouter(StatusLinkWithRouter);
